<mat-card>
    <mat-card-header>
        <img mat-card-avatar [src]="action.realtor | realtor" [matTooltip]="action.realtor | realtorName" />
        <mat-card-title>{{action.originName}}</mat-card-title>
        <mat-card-subtitle *ngIf="action.referral">
            Referral: {{action.referral | param:'allUsers'}}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content *ngIf="action.note">
        <p>{{action.note}}</p>
    </mat-card-content>
</mat-card>