import { Component, ChangeDetectorRef, Inject } from '@angular/core';
import { MainComponent } from '../../common/pages/main/main.component';
import { Project } from '../../services/project/project.model';
import { SettingService } from '../../common/services/setting/setting.service';
import { ProjectService } from '../../services/project/project.service';
import { SelectService } from '../../common/services/select/select.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatchingService } from '../../services/matching/matching.service';
import { ActionService } from '../../services/action/action.service';
import { DialogConfirmationComponent } from '../../common/components/dialog-confirmation/dialog-confirmation.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwapAgentComponent } from '../../components/actions-dialog/swap-agent/swap-agent.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { TemperatureComponent } from 'src/components/actions-dialog/temperature/temperature.component';
import { Action } from 'src/services/action/action.model';
import { Subscription } from 'rxjs';
import { Tools } from 'src/common/services/tools';


@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent extends MainComponent {

  public tools = new Tools();
  public item: Project;
  public matching: MatchingService = null;
  public displayAction: any;
  public dashDisplayActions = ['newRequest', 'visit', 'offer'];
  public dashActions: { [actionType: string]: { icon: string, label: string, nb: number, timeago: string } } =
    {
      newRequest: { icon: this.GLOBAL.ICONS.NEWREQUEST, label: 'Leads', nb: 0, timeago: '' },
      visit: { icon: this.GLOBAL.ICONS.VISIT, label: 'Visits', nb: 0, timeago: '' },
      offer: { icon: this.GLOBAL.ICONS.OFFER, label: 'Offers', nb: 0, timeago: '' }
    };
  public newAction = { check: false };

  public desc: string;

  public matchSelect: boolean = false;
  public matchNb: { new: number, match: number, total: number } = { new: 0, match: 0, total: 0 };
  public matchOnly: boolean = true;

  // selectSubscription: any;
  private _actionSubscription: Subscription;
  private _matchingSubscription: Subscription;

  // private _keepFilterMatching: any = {newOnly: false, matchingOnly: true, visit: true};

  public actionButtons: { id: string, icon: string, color?: string, label?: string }[] = [];


  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ProjectService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
    public actionService: ActionService,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog, actionService);
    this.item = new Project(null, setting);
    this.matching = new MatchingService(this.db.auth, this.db.afs);
    // this.actionService = new ActionService(this.db.auth, this.db.afs);
  }

  get actionSynap(): any {
    return { 'projectsActions': { [this.item.values.$key]: this.item.createAlias(['contactsProjects']) } };
  }

  _itemOnInit() {
    // this.matching.initMatching('project', this.item.values.$key);
    if (this.item.values.$key) {
      this.actionService.setServerFilter(
        {
          filters: {
            'project.$key': this.item.values.$key
          },
          sort: { active: 'date', direction: 'desc' },
        }
      );
      this._actionSubscription = this.actionService.refresh.subscribe(() => {
        this._updateDash();
        this._cd.detectChanges();
      })

      this.matching.setServerFilter(
        {
          filters: {
            'project.$key': this.item.values.$key
          },
        }
      );
      this._matchingSubscription = this.matching.refresh.subscribe(() => {
        this._cd.detectChanges();
      })
    }

  }

  protected _manageMenu() {
    this.actionButtons = [{
      id: 'sms',
      icon: this.GLOBAL.ICONS.NOTE,
      color: 'link',
      label: 'Add Note',
    }];

    if (this.item.values.status !== 'failed' && this.item.values.status !== 'deal') {
      if (this.item.values.status !== 'pause' && this.item.values.status !== 'discovery') {
        this.actionButtons.push({
          id: 'temperature',
          icon: this.GLOBAL.ICONS.TEMPERATURE,
          color: 'warn',
          label: 'Change Temperature',
        });
      }
      this.actionButtons.push({
        id: 'call',
        icon: 'phone',
        color: 'primary',
        label: 'Call',
      });
      this.actionButtons.push({
        id: 'NACall',
        icon: 'phone_disabled',
        color: 'primary',
        label: 'Not Answering',
      });
      this.actionButtons.push({
        id: 'email',
        icon: 'email',
        color: 'primary',
        label: 'Message',
      });
      if (this.item.values.status !== 'pause' && this.item.values.status !== 'discovery') {
        this.actionButtons.push({
          id: 'visit',
          icon: 'directions_walk',
          color: 'primary',
          label: 'Visit',
        });
      }
    }
  }


  private _updateDash() {
    // Updates Actions Values

    if (this.actionService.items) {

      this.dashActions.newRequest.nb = 0;
      this.dashActions.newRequest.timeago = '';
      // this.nextActions = [];
      this.displayAction = null;

      for (const action of this.actionService.items) {
        {
          // Update Summary Nb of Actions
          if (this.dashActions[action.type]) {
            if (!this.dashActions[action.type].timeago) {
              this.dashActions[action.type].timeago = this.tools.timeago(action.date);
            }
            this.dashActions[action.type].nb++;
          }
          //Get Last Action
          if (!action.done) {
            this.displayAction = action;
          }
        }
      }
      if (!this.displayAction && this.actionService.items && this.actionService.items[0]) {
        this.displayAction = this.actionService.items[0];
      }
    }
  }


  protected _afterBindUpdates() {
    this._manageMenu();
    this.matchNb = { new: 0, match: 0, total: 0 };
    if (this.item.values.synaps && this.item.values.synaps.projectMatchings) {
      for (const matchKey in this.item.values.synaps.projectMatchings) {
        if (this.item.values.synaps.projectMatchings[matchKey].new) {
          this.matchNb.new++;
        } else if (this.item.values.synaps.projectMatchings[matchKey].match) {
          this.matchNb.match++;
          this.matchNb.total++;
        } else {
          this.matchNb.total++;
        }
      }
    }
  }

  protected _ngOnDestroy() {
    // if (this.selectSubscription) {
    //   this.selectSubscription.unsubscribe();
    // }
    if (this._actionSubscription) {
      this._actionSubscription.unsubscribe();
    }
    if (this._matchingSubscription) {
      this._matchingSubscription.unsubscribe();
    }
  }

  goBack() {
    if (this.select.active) {
      this.cancelVisitMatching();
    } else {
      this.router.navigate([this.backURL.back()]);
    }
  }

  sectorsModified(sectors: string[]) {
    this.item.values.sectors = sectors;
    this.item.modify.sectors = true;
    this.item.modified = true;

    let valid = true;
    for (const sectionName in this.item.activeSections) {
      if (!this.item.activeSections[sectionName].valid) {
        valid = false;
      }
    }
    this.item.valid = valid;
  }

  acceptProject() {
    this._changeStatus('discovery', false);
  }


  cancelProjectEdit() {
    this.cancelEdit();
    this.displayItem();
  }

  beforeSaveItem() {
    // check if need to launch matching
    if (this.item.values.status === 'active') {
      this.item.values.matching = true;
      this.item.modify.matching = true;
    }
  }

  createActionMenu(actionType: string) {

    if (actionType === 'offer'
      || actionType === 'visit'
      || actionType === 'signAgree'
      || actionType === 'signDeed'
      || actionType === 'signLease') {
      this.newAction.check = true;
    }

    if (actionType === 'temperature') {
      this._temperature();
    } else {
      this.actionService.createAction(Action, actionType,
        [{ item: this.item }],
        {
          realtor: this.item.values.realtor,
          agency: this.item.values.agency,
          done: actionType === 'NACall' ? true : false,
          syncList: true
        }
      );
    }
  }

  actionDone(action: any) {
    this.saveItem();

    if (action.type === 'discovery') { // Change project status and copy information to the project
      this.pageConfig.edit = true;
      this.pageConfig.mainTab = 2;
    } else if (action.type === 'visit') { // Create Owner Reports Actions for each mandate
      for (const matchingKey in action.synaps.visit) {
        this._createVisitReport(matchingKey, action.synaps.visit[matchingKey].mandateKey, action.date, {});
        this.pageConfig.mainTab = 3;
      }
    }
  }


  addMatching(action: any) {
    if (!this.matchSelect) {
      this.matchSelect = true;
      this.pageConfig.mainTab = 3;
    }
  }

  backToMatching() {
    this.pageConfig.mainTab = 3;
  }

  cancelVisitMatching() {
    if (this.matchSelect) {
      this.matchSelect = false;
      this.select.reset();
      this.pageConfig.mainTab = 1;
    }
  }

  saveVisitMatching() {
    this.select.done();
    this.matchSelect = false;
    this.pageConfig.mainTab = 1;
  }

  addMatchingMandate() {
    this.select.initAddMatching(this.item, this.db, `/projects/${this.item.values.$key}`);
  }

  tabChanged() {
    // if (this.pageConfig.mainTab === 2) {
    //   this.matching.setLocalFilter(this._keepFilterMatching);
    // } else if (this.pageConfig.mainTab === 3) {
    //   this._keepFilterMatching = this.matching.localFilters;
    //   this.matching.setLocalFilter({ matchOnly: false, newOnly: true });
    // } else {
    //   this._keepFilterMatching = this.matching.localFilters;
    // }
  }


  pause(pause: boolean) {
    if (pause) {
      const data = { title: 'Pause the Project?', ok: false };
      const dialogRef = this.dialog.open(DialogConfirmationComponent, {
        width: '250px',
        disableClose: true,
        data: data
      });

      dialogRef.afterClosed().subscribe(() => {
        if (data.ok) {
          this._changeStatus('pause');
        }
      });
    } else {
      const data = { title: 'Active the Project?', ok: false };
      const dialogRef = this.dialog.open(DialogConfirmationComponent, {
        width: '250px',
        disableClose: true,
        data: data
      });


      dialogRef.afterClosed().subscribe(() => {
        if (data.ok) {
          // Check if no discovery action -> go to status discovery an recreate the discovery action
          let discovery = false;
          if (this.item.values.synaps.projectsActions) {
            for (const actionKey in this.item.values.synaps.projectsActions) {
              if (this.item.values.synaps.projectsActions[actionKey] && this.item.values.synaps.projectsActions[actionKey].type && this.item.values.synaps.projectsActions[actionKey].type === 'discovery') {
                discovery = true;
              }
            }
          }
          if (discovery) {
            this._changeStatus('active');
          } else {
            this._changeStatus('discovery');
          }
        }
      });
    }
  }

  handover() {
    const data: any = { realtor: [this.item.values.realtor], agency: this.item.values.agency };
    const dialogRef = this.dialog.open(SwapAgentComponent, {
      width: '450px',
      disableClose: true,
      data: data
    });


    dialogRef.afterClosed().subscribe(async () => {
      if ((data.realtor && data.realtor[0] && data.realtor[0] !== this.item.values.realtor) || (data.agency && data.agency !== this.item.values.agency)) {
        this.startSaving();
        this.item.values.actionUpdated = new Date();
        this.item.modify.actionUpdated = true;
        this.item.values.realtor = data.realtor[0];
        this.item.modify.realtor = true;
        this.item.values.agency = data.agency;
        this.item.modify.agency = true;
        this.saveItem();

      }
    });

  }

  private _temperature() {
    const data: any = { temperature: [this.item.values.temperature], icon: this.GLOBAL.ICONS.PROJECT };
    const dialogRef = this.dialog.open(TemperatureComponent, {
      width: '450px',
      disableClose: true,
      data: data
    });


    dialogRef.afterClosed().subscribe(async () => {
      if (data.temperature && data.temperature[0] && data.temperature[0] !== this.item.values.temperature) {
        this.startSaving();
        this.item.values.actionUpdated = new Date();
        this.item.modify.actionUpdated = true;
        this.item.values.temperature = data.temperature[0];
        this.item.modify.temperature = true;
        this.saveItem();
      }
    });

  }

  private _changeStatus(status: string, match = true) {
    this.item.values.status = status;
    this.item.modify.status = true;
    this.item.values.actionUpdated = new Date();
    this.item.modify.actionUpdated = true;
    if (match) {
      this.item.values.matching = true;
      this.item.modify.matching = true;
    }
    this.saveItem();
  }



  private _createVisitReport(matchingKey: string, mandateKey: string, date: Date, data: any) {

    // Get mandate info first

    for (const matching of this.matching.items) {
      if (matching.$key === matchingKey) {
        const mandate = matching.mandate ? matching.mandate : {};

        this.actionItem.resetItem();
        Object.assign(this.actionItem.values, {
          type: 'visitReport',
          date: date,
          data: data,
          done: false,
          realtor: mandate.realtor ? mandate.realtor : null,
          synaps:
          {
            mandatesActions: { [mandateKey]: {} },
            visitReport: { [matchingKey]: {} },
          }
        });

        this.actionService.saveNewItem(this.actionItem);
      }
    }

  }

  failed() {
    const data = { ok: false, failedReason: null, note: null };
    const dialogRef = this.dialog.open(DialogFailedProjectComponent, {
      width: '350px',
      disableClose: true,
      data: data
    });


    dialogRef.afterClosed().subscribe(async () => {
      if (data.ok) {
        this.item.values.actionUpdated = new Date();
        this.item.modify.actionUpdated = true;
        this.item.values.failedReason = data.failedReason;
        this.item.modify.failedReason = true;
        this.item.values.failedNote = data.note;
        this.item.modify.failedNote = true;
        this.item.values.status = 'failed';
        this.item.modify.status = true;
        this.item.values.dateEnd = new Date();
        this.item.modify.dateEnd = true;
        this.item.values.matching = true;
        this.item.modify.matching = true;
        this.saveItem();
      }
    });

  }


  colorProjectDate(): string {
    if (this.item.values.dateNeed) {
      let dateNeedMoment;
      if (this.item.values.dateNeed instanceof Date) {
        dateNeedMoment = this.item.values.dateNeed;
      } else {
        // Assuming this.item.values.dateNeed is a valid date string
        dateNeedMoment = new Date(this.item.values.dateNeed);
      }

      const now = new Date();
      const days = Math.floor((now.getTime() - dateNeedMoment.getTime()) / (1000 * 60 * 60 * 24));

      if (this.item.values.type === 'sale') {
        if (days < -60) {
          return 'active';
        }
        if (days < -30) {
          return 'warm';
        }
        return 'hot';
      }

      if (days < -14) {
        return 'active';
      }
      if (days > -7) {
        return 'warm';
      }
      return 'hot';
    }
    return '';
  }


}


@Component({
  selector: 'app-dialog-failed-project',
  templateUrl: './failed-project.component.html',
})
export class DialogFailedProjectComponent {

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.data.ok = true;
    this.dialogRef.close();
  }
}


