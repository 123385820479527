import { BatchProvider } from '../tools/batch';
import { PreSettings } from '../shared/setting-model';
import { formatCy, formatNumberWithCommas, getWebURLs, land_m2, mergeResidenceUnitSynap } from './tools';
import { GlobalConstants } from './global-constants';

const cies: any = {
    mur: 'Rs',
    eur: '€',
    usd: '$',
    zar: 'R',
};

const preSettings = new PreSettings();
const GLOBAL = new GlobalConstants();

export default function updateList(batch: BatchProvider, data: any, params: any) {
    const modulePath = `list/modules/${params.module}/${params.key}`;
    const mandatesMatchingPath = `mandatesMatching/${params.key}`;
    const projectsMatchingPath = `projectsMatching/${params.key}`;


    if (data) {
        // if (newItem) {
        //     batch.set(modulePath, getModuleData(params.module, data));
        // } else {
        batch.set(modulePath, getModuleListData(params.module, data));
        // }
        if (params.module === 'mandates') {
            handleMatchingMandate(batch, data, mandatesMatchingPath);
        }
    } else {
        batch.delete(modulePath);
        if (params.module === 'mandates') {
            batch.delete(mandatesMatchingPath);
        }
        if (params.module === 'projects') {
            batch.delete(projectsMatchingPath);
        }
    }
}

export function getModuleListData(module: string, data: any) {
    switch (module) {
        case 'actions': return actions(data);
        case 'blogs': return blogs(data);
        case 'contacts': return contacts(data);
        case 'companies': return companies(data);
        case 'mandates': return mandates(data);
        case 'matchings': return matchings(data);
        case 'projects': return projects(data);
        case 'residences': return residences(data);
        case 'units': return units(data);
        default: return null;
    }
}

function handleMatchingMandate(batch: BatchProvider, data: any, matchingPath: string) {
    if (['active', 'offer', 'signAgree', 'signLease'].includes(data.status) && data.synaps && data.synaps.unitMandates && Object.keys(data.synaps.unitMandates).length) {
        batch.set(matchingPath, mandatesMatching(data));
    } else {
        batch.delete(matchingPath);
    }
}



export function createList(data: any, module: string) {

    switch (module) {
        case 'actions': {
            return actions(data)
        }
        case 'contacts': {
            return contacts(data);
        }
        case 'companies': {
            return companies(data);
        }
        case 'residences': {
            return residences(data);
        }
        case 'mandates': {
            return mandates(data);
        }
        case 'matchings': {
            return matchings(data);
        }
        case 'projects': {
            return projects(data);
        }
        case 'units': {
            return units(data);
        }
        default: {
            return null;
        }
    }
}


const icons: any = {
    house: 'home',
    apartment: 'apartment',
    office: 'work',
    commercial: 'shopping_cart',
    land: 'crop_free',
    building: 'location_city',
}


export function units(data: any): any {

    const residence: any = mergeResidenceUnitSynap(data);

    const listObj: any = {
        $key: _checkUndefined(data.$key),
        icon: data.type && icons[data.type] ? icons[data.type] : null,
        iconColor: data.vefa ? 'vefa' : '',
        ref: _checkUndefined(data.ref),
        alerts: isNaN(data.alerts) ? (data.alerts ? data.alerts.length : 0) : data.alerts,
        vefa: !!data.vefa,
        pm: !!data.pm,
        vefaName: _checkUndefined(data.vefaName),
        vefaNb: _checkUndefined(data.vefaNb),
        vefaAv: _checkUndefined(data.vefaAv),
        vefaNbDisp: `${data.vefaAv !== null ? data.vefaAv : '?'}/${data.vefaNb !== null ? data.vefaNb : '?'}`,
        type: _checkUndefined(data.type),
        city: _checkUndefined(data.address?.eCityName),
        gps: _checkUndefined(data.address?.gps),
        unitNb: _checkUndefined(data.address?.unitNb),
        sectors: _checkUndefined(data.address?.sectors || []),
        sector: _checkUndefined(data.address?.sector),
        bedrooms: _checkUndefined(data.bedrooms),
        bathrooms: _checkUndefined(data.bathrooms),
        condition: _checkUndefined(data.condition),
        surface: null,
        includeAC: !!data.includeAC,
        photo: _checkUndefined(data.photo || (data.photos && data.photos.length ? data.photos[0] : null)),
        mandateTypes: [],
        rentalPrice: 0,
        rentalCy: 'MUR',
        salePrice: 0,
        saleCy: 'MUR',
        foreigner: false,
        residence: null,
        scheme: null,
        mandates: [],
        owners: [],
    }


    // Surface
    if (data.type === 'land') { //TODO: separate land from interior surface
        listObj.surface = (data.landSurface && data.landUnit) ? land_m2(data.landSurface, data.landUnit) : null;
    } else {
        listObj.surface = data.builtSurface ? data.builtSurface : (data.livingSurface ? data.livingSurface : null);
    }


    if (residence) {
        listObj.residence = residence.name ? residence.name : null;
        listObj.scheme = residence.scheme ? residence.scheme : null;
    }

    if (data.synaps) {

        if (!data.vefa && data.synaps.owners) {
            for (const contactKey in data.synaps.owners) {
                if (data.synaps.owners[contactKey] && data.synaps.owners[contactKey].fullname) {
                    listObj.owners.push(data.synaps.owners[contactKey].fullname);
                }
            }
        }
        if (data.vefa && residence) {
            for (const residenceKey in data.synaps.residenceUnits) {
                if (data.synaps.residenceUnits[residenceKey] && data.synaps.residenceUnits[residenceKey].synaps && data.synaps.residenceUnits[residenceKey].synaps.promoter) {
                    for (const companyKey in data.synaps.residenceUnits[residenceKey].synaps.promoter) {
                        if (data.synaps.residenceUnits[residenceKey].synaps.promoter[companyKey] && data.synaps.residenceUnits[residenceKey].synaps.promoter[companyKey].displayname) {
                            listObj.owners.push(data.synaps.residenceUnits[residenceKey].synaps.promoter[companyKey].displayname);
                        }
                    }
                }
            }
        }

        if (data.synaps.unitMandates) {
            for (const mandateKey in data.synaps.unitMandates) {
                if (data.synaps.unitMandates[mandateKey].status === 'active') {
                    listObj.mandateTypes.push(data.synaps.unitMandates[mandateKey].type);
                    listObj[data.synaps.unitMandates[mandateKey].type + 'Price'] = data.synaps.unitMandates[mandateKey].price;
                    listObj[data.synaps.unitMandates[mandateKey].type + 'Cy'] = data.synaps.unitMandates[mandateKey].cy;
                    listObj[data.synaps.unitMandates[mandateKey].type + 'Realtor'] = data.synaps.unitMandates[mandateKey].realtor;
                    if (data.synaps.unitMandates[mandateKey].type === 'sale') {
                        listObj.mandates.push(formatNumberWithCommas(listObj.salePrice) + ' ' + cies[listObj.saleCy]);

                        // if ((listObj.residence && listObj.scheme) || (listObj.type !== 'house')) {
                        //     listObj.foreigner = true;
                        // }
                    } else if (data.synaps.unitMandates[mandateKey].type === 'rental') {
                        listObj.mandates.push(formatNumberWithCommas(listObj.rentalPrice) + ' ' + cies[listObj.rentalCy]);
                    }
                    if (!data.vefa) {
                        listObj.iconColor = 'active';
                    }
                }
            }
        }

    }

    return listObj;
}

export function residences(data: any): any {

    const schemes: any = {
        irs: 'IRS',
        res: 'RES',
        ihs: 'IHS',
        pds: 'PDS',
        senior: 'Senior Residence',
        smart: 'Smart City',
    };

    const listObj: any = {
        $key: data.$key ? data.$key : null,
        ad: !!data.ad,
        name: data.name ? data.name : null,
        icon: 'domain',
        iconColor: null,
        ref: data.ref ? data.ref : '????',
        alerts: data.alerts ? data.alerts.length : 0,
        types: data.types ? data.types : [],
        scheme: data.scheme ? data.scheme : null,
        schemeDisp: data.scheme ? schemes[data.scheme] : null,
        status: data.status ? data.status : null,
        statusDisp: data.status ? preSettings.getParamName('residenceStatus', data.status) : '',
        city: data.address && data.address.eCityName ? data.address.eCityName : null,
        gps: data.address && data.address.gps ? data.address.gps : null,
        sector: data.address && data.address.sector ? data.address.sector : null,
        photo: (data.photos && data.photos.length) ? data.photos[0] : null,
        totalUnits: data.totalUnits ? data.totalUnits : 0,
        units: 0,
        nb: '',
        promoter: null,
        syndic: null,
        realtor: data.realtor ? data.realtor : null,
        agency: data.agency ? data.agency : null,
        // synaps: data.synaps ? data.synaps : {},
        // alias: alias,
    }


    if (data.synaps) {
        // UNIT
        if (data.synaps.residenceUnits) {
            listObj.units = Object.values(data.synaps.residenceUnits).filter((unit: any) => !unit.vefa).length;
            listObj.nb = listObj.units + '/' + listObj.totalUnits;
        }
        if (data.synaps.syndic) {
            for (const companyKey in data.synaps.syndic) {
                if (data.synaps.syndic[companyKey].displayname) {
                    listObj.syndic = data.synaps.syndic[companyKey].displayname;
                }
            }
        }
        if (data.synaps.promoter) {
            for (const companyKey in data.synaps.promoter) {
                if (data.synaps.promoter[companyKey].displayname) {
                    listObj.promoter = data.synaps.promoter[companyKey].displayname;
                }
            }
        }
    }

    return listObj;
}

// function getMandateIcon(status: string): string {
//     if (status === 'active') {
//         return 'book';
//     }
//     if (status === 'failed') {
//         return 'cancel';
//     }
//     if (status === 'deal') {
//         return 'task_alt';
//     }
//     if (status === 'pending') {
//         return 'sync';
//     }
//     if (status === 'pause') {
//         return 'pause';
//     }
//     if (status === 'estimation') {
//         return 'monetization_on';
//     }
//     return 'book';
// }

export function getActionIcon(type: string): string {
    const actionList: any = preSettings.getParamValues('actionTypes');

    if (actionList[type]) {
        return actionList[type].icon;
    }
    return 'exit_to_app';
}



// function priceDisplay(data: any): string {
//     let price = '';
//     if (data.priceCies && data.priceCies.mur) {
//         price += formatNumberWithCommas(data.priceCies.mur);

//         if (data.priceNegCies && data.priceNegCies.mur) {
//             price += ' (' + formatNumberWithCommas(data.priceNegCies.mur) + ')';
//         }
//         price += ' Rs';
//     }
//     return price;
// }


function priceDisplay(price: number, cy: string): string | null {
    if (price) {
        return formatNumberWithCommas(price) + ' ' + cyDisplay(cy);
    }
    return null;
}

// function priceDisplayFurnitureBrut(data: any): string {
//     let price = '';
//     if (data.priceFurniture) {
//         price += formatNumberWithCommas(data.priceFurniture);
//         price += ' ' + cyDisplay(data.cy);
//     }
//     return price;
// }

function cyDisplay(cy: string): string {
    switch (cy) {
        case 'mur': {
            return 'Rs';
            break;
        }
        case 'eur': {
            return '€';
        }
        case 'usd': {
            return '$';
        }
        case 'zar': {
            return 'Rd';
        }
        default: {
            return '?';
        }
    }
}

export function mandates(data: any) {

    const listObj: any = {
        $key: data.$key ? data.$key : null,
        icon: preSettings.getParamIcon('mandateStatus', data.status),
        ref: data.ref ? data.ref : '????',
        dateAvailable: data.dateAvailable ? data.dateAvailable : null,
        price: (data.priceNegCies && data.priceNegCies.mur) ? data.priceNegCies.mur : ((data.priceCies && data.priceCies.mur) ? data.priceCies.mur : null),
        priceDisplay: priceDisplay(data.price, data.cy),
        priceNegDisplay: priceDisplay(data.priceNeg, data.cy),
        realtor: data.realtor ? data.realtor : null,
        referral: data.referral ? data.referral : null,
        referralAgency: data.referralAgency ? data.referralAgency : null,
        referralCom: data.referralCom ? data.referralCom : null,
        agency: data.agency ? data.agency : null,
        status: data.status ? data.status : null,
        temperature: data.temperature ? data.temperature : null,
        type: data.type ? data.type : null,
        owners: [],
        matchings: 0,
        newMatchings: 0,
        matchingsDisplay: '',
        // synaps: data.synaps ? data.synaps : {},
        // alias: alias,
        // stats: {
        //     newRequest: { nb: 0, date: '' },
        //     visitReport: { nb: 0, date: '' },
        //     offer: { nb: 0, date: '' }
        // }
    }
    listObj.iconColor = data.status === 'active' ? (listObj.temperature ? listObj.temperature : null) : listObj.status;

    if (data.synaps) {
        // UNIT
        if (data.synaps.unitMandates) {
            for (const unitKey in data.synaps.unitMandates) {
                const unit = data.synaps.unitMandates[unitKey];
                getUnitData(listObj, unit);
            }
        }
        if (listObj.condition === 'vefa') {
            listObj.iconColor = 'vefa';
        }

        // MATCHING
        if (data.synaps.mandateMatchings) {
            for (const matchingKey in data.synaps.mandateMatchings) {
                if (data.synaps.mandateMatchings[matchingKey]) {
                    if (data.synaps.mandateMatchings[matchingKey].active) {
                        listObj.matchings++;
                    }
                    if (data.synaps.mandateMatchings[matchingKey].new) {
                        listObj.newMatchings++;
                    }
                }
            }
            listObj.matchingsDisplay = listObj.newMatchings ? (listObj.newMatchings + ' New!') : (listObj.matchings + '');
        }
        // ACTIONS
        // if (data.synaps.mandatesActions) {
        //     for (const actionKey in data.synaps.mandatesActions) {
        //         {
        //             const action = data.synaps.mandatesActions[actionKey];
        //             if (listObj.stats[action.type]) {
        //                 listObj.stats[action.type].nb++;
        //             }
        //         }
        //     }
        // }
    }


    return listObj;
}

export function projects(data: any): any {

    const listObj: any = {
        $key: data.$key ? data.$key : null,
        status: data.status,
        icon: preSettings.getParamIcon('projectStatus', data.status),
        iconColor: data.status ? (data.status === 'active' ? (data.temperature ? data.temperature : null) : data.status) : '',
        ref: data.ref ? data.ref : '????',
        created: data.created ? data.created : null,
        dateNeed: data.dateNeed ? data.dateNeed : null,
        dateEnd: null,
        duration: null,
        maxBudget: data.maxBudget ? data.maxBudget : null,
        minBudget: data.minBudget ? data.minBudget : (data.maxBudget ? Math.round(data.maxBudget / 2) : null),
        budget: budgetDisplay(data),
        realtor: data.realtor ? data.realtor : null,
        agency: data.agency ? data.agency : null,
        referral: data.referral ? data.referral : null,
        referralAgency: data.referralAgency ? data.referralAgency : null,
        referralCom: data.referralCom ? data.referralCom : null,
        temperature: data.temperature ? data.temperature : null,
        type: data.type ? data.type : null,
        minBedrooms: data.minBedrooms ? data.minBedrooms : null,
        minBathrooms: data.minBathrooms ? data.minBathrooms : null,
        unitTypeIcons: getUnitTypesIcons(data.unitTypes),
        unitTypes: data.unitTypes ? data.unitTypes : [],
        conditions: data.conditions ? data.conditions : [],
        contactsProjects: [],
        matchings: 0,
        newMatchings: 0,
        matchingsDisplay: '',
        // synaps: data.synaps ? data.synaps : {},
        // alias: alias,
    }
    // console.log('test1');
    if (data.synaps) {
        // CONTACTS
        if (data.synaps.contactsProjects) {
            for (const contactKey in data.synaps.contactsProjects) {
                if (data.synaps.contactsProjects[contactKey] && data.synaps.contactsProjects[contactKey].fullname) {
                    listObj.contactsProjects.push(data.synaps.contactsProjects[contactKey].fullname);
                }
            }
        }


        // ACITONS
        // if (data.synaps.projectsActions) {
        //     for (const actionKey in data.synaps.projectsActions) {
        //         if (data.synaps.projectsActions[actionKey] && data.synaps.projectsActions[actionKey].type) {
        //             if (data.synaps.projectsActions[actionKey].type === 'failed' && data.status === 'failed') {
        //                 listObj.dateEnd = data.synaps.projectsActions[actionKey].date;
        //             } else if (data.synaps.projectsActions[actionKey].type === 'failed' && data.status === 'deal') {
        //                 listObj.dateEnd = data.synaps.projectsActions[actionKey].date;
        //             }
        //         }
        //     }
        // }

        // MATCHING
        if (data.synaps.projectMatchings) {
            for (const matchingKey in data.synaps.projectMatchings) {
                if (data.synaps.projectMatchings[matchingKey]) {
                    if (data.synaps.projectMatchings[matchingKey].active) {
                        listObj.matchings++;
                    }
                    if (data.synaps.projectMatchings[matchingKey].new) {
                        listObj.newMatchings++;
                    }
                }
            }
            listObj.matchingsDisplay = listObj.newMatchings ? (listObj.newMatchings + ' New!') : (listObj.matchings + '')
        }

    }
    // console.log('test2');
    // Check if need to run matching

    // if (checkMatchingProjectFields(data)) {
    //     listObj.matching = true;
    // }


    return listObj;

}


function budgetDisplay(data: any): string {
    let budget = '';
    if (data.maxBudget) {
        if (data.minBudget) {
            budget += formatNumberWithCommas(data.minBudget);
        } else {
            budget += '-';
        }
        budget += ' < '
        budget += formatNumberWithCommas(data.maxBudget);
        budget += ' ' + formatCy(data.cy);
    }
    return budget;
}

function getUnitTypesIcons(unitTypes: string[]): string[] {
    const unitTypeIcons: string[] = [];

    if (unitTypes) {
        for (const type of unitTypes) {
            if (icons[type]) {
                unitTypeIcons.push(icons[type]);
            }
        }
    }

    return unitTypeIcons;
}

function getSectors(sectors: []): string {
    let value = '';

    if (sectors && sectors.length) {
        let i = 0;
        for (const sector of sectors) {
            for (const sectorSetting of preSettings.regions.sectors) {
                if (sector === sectorSetting.name) {
                    value += (i > 0 ? ', ' : '') + sectorSetting.title;
                    i++;
                }
            }
        }
    }

    return value;
}

// function getParamNames(paramType: string, list: string[]): string {
//     let value = '';

//     if (paramType && list && list.length) {
//         const params: any = preSettings.getParamValues(paramType);
//         if (params) {
//             let i = 0;
//             for (const param of list) {
//                 if (params[param]) {
//                     value += (i > 0 ? ', ' : '') + params[param].name;
//                 }
//                 i++;
//             }
//         }
//     }

//     return value;
// }



export function blogs(data: any): any {

    const listObj: any = {
        $key: data.$key ? data.$key : null,
        ref: data.ref ? data.ref : '????',
        date: data.date ? data.date : null,
        created: data.created ? data.created : null,
        icon: GLOBAL.ICONS.BLOG,
        iconColor: data.online ? 'online' : '',
        topcis: data.topics ? data.topics : [],
        title: (data.title && data.title.en) ? data.title.en : '',
    }

    return listObj;
}


export function contacts(data: any): any {

    const listObj: any = {
        $key: data.$key ? data.$key : null,
        icon: 'person',
        iconColor: '',
        ref: data.ref ? data.ref : '????',
        title: data.title ? data.title : '',
        firstname: data.firstname ? data.firstname : '',
        lastname: data.lastname ? data.lastname : '',
        fullname: data.fullname ? data.fullname : _fullname(data),
        lang: data.lang ? data.lang : null,
        nationalities: data.nationalities ? data.nationalities : [],
        emails: data.emails ? data.emails : [],
        tels: data.tels ? data.tels : [],
        country: data.country ? data.country : null,
        birthday: data.birthday ? data.birthday : null,
        units: 0,
        projects: 0,
        owner: false,
        renter: false,
        buyer: false,
        renterActive: false,
        buyerActive: false,
        client: false,
        agencies: {},
        realtors: {},
    }

    if (data.synaps) {
        if (data.synaps.owners) {
            listObj.units = Object.keys(data.synaps.owners).length;
            if (listObj.units) {
                listObj.owner = true;
            }
        }
        if (data.synaps.contactsProjects) {
            for (const projectKey in data.synaps.contactsProjects) {
                const project = data.synaps.contactsProjects[projectKey];

                listObj.projects++;
                if (project.status === 'deal') {
                    listObj.client = true;
                }
                if (project.type === 'sale') {
                    listObj.buyer = true;
                    if (project.status === 'active') {
                        listObj.buyerActive = true;
                    }
                } else if (project.type === 'rental') {
                    listObj.renter = true;
                    if (project.status === 'active') {
                        listObj.renterActive = true;
                    }
                }
                if (project.agency) {
                    listObj.agencies[project.agency] = true;
                }
                if (project.realtor) {
                    listObj.realtors[project.realtor] = true;
                }
            }
        }

    }

    return listObj;
}

function _fullname(data: any): string {

    const titles: { [id: string]: string } = {
        'MR': 'Mr',
        'MRS': 'Mrs',
        'MISS': 'Ms',
    };

    let fullname = '';
    if (data) {
        if (data.title) {
            fullname += titles[data.title] + ' ';
        }
        if (data.firstname) {
            fullname += data.firstname;
        }
        if (data.firstname && data.lastname) {
            fullname += ' ';
        }
        if (data.lastname) {
            fullname += data.lastname;
        }
        if (data.nickname) {
            fullname += ' (' + data.nickname + ')';
        }
    }
    return fullname;
}

export function companies(data: any): any {

    const listObj: any = {
        $key: data.$key ? data.$key : null,
        icon: 'work',
        iconColor: '',
        ref: data.ref ? data.ref : '????',
        displayname: data.displayname ? data.displayname : '????',
        employees: [],
        // synaps: data.synaps ? data.synaps : {},
        // alias: alias,
    }


    if (data.synaps) {
        if (data.synaps.employees) {
            for (const contactKey in data.synaps.employees) {
                if (data.synaps.employees[contactKey].fullname) {
                    listObj.employees.push(data.synaps.employees[contactKey].fullname);
                }
            }
        }


    }

    return listObj;
}

export function actions(data: any) {

    const listObj: any = {
        $key: data.$key ? data.$key : null,
        realtor: data.realtor ? data.realtor : null,
        agency: data.agency ? data.agency : null,
        icon: getActionIcon(data.type),
        iconColor: _actionIconColor(data),
        done: !!data.done,
        type: data.type ? data.type : null,
        medium: data.medium ? data.medium : null,
        date: data.date ? data.date : null,
        // data: data.data ? data.data : null,
        doneDate: data.doneDate ? data.doneDate : null,
        note: data.note ? data.note : null,
        module: data.module ? data.module : null,
        mandate: {},
        matchings: [],
        project: {},
        residence: {},
        fullnames: [],
        // synaps: data.synaps ? data.synaps : {},
        // alias: alias,
    }

    // NEW REQUEST
    if (data.type === 'newRequest' && data.data) { // get info to display
        listObj.note = data.data.msg ? data.data.msg : null;
        listObj.origin = data.data.origin;
        listObj.originName = _getProjectOrigin(data.data.origin);
        listObj.medium = data.data.medium ? data.data.medium : null;
        listObj.notAssigned = !(data.synaps && data.synaps.projectsActions);
        listObj.fullnames.push(_fullname(data.data));
        listObj.referral = data.data.referral ? data.data.referral : null;
    }
    // DISCOVERY
    else if (data.type === 'discovery' && data.data) {
        listObj.dateNeed = data.data.dateNeed ? data.data.dateNeed : null;
        listObj.temperature = data.data.temperature ? data.data.temperature : null;
        listObj.foreigner = !!data.data.foreigner;
        listObj.residentPermit = !!data.data.residentPermit;
        listObj.sectors = getSectors(data.data.sectors);
        listObj.unitTypeIcons = getUnitTypesIcons(data.data.unitTypes);
        listObj.minBedrooms = data.data.minBedrooms ? data.data.minBedrooms : null;
        listObj.maxBudget = priceDisplay(data.data.maxBudget, data.data.cyBudget);
    }
    // OFFER && SIGN
    else if ((data.type === 'offer'
        || data.type === 'signAgree'
        || data.type === 'signDeed'
        || data.type === 'signLease') && data.data) {
        listObj.accepted = !!data.data.accepted;
        // listObj.priceDisplay = priceDisplay(data.data.price, data.data.cy);
        // if (data.cat === 'sale') {
        //     listObj.furniturePriceDisplay = priceDisplay(data.data.priceFurniture, data.data.cy);
        // }
        listObj.note = data.data.note ? data.data.note : null;

    }
    // R1
    else if (data.type === 'r1' && data.data) {
        listObj.temperature = data.data.temperature ? data.data.temperature : null;
        listObj.note = data.data.note ? data.data.note : null;
    }
    // R2
    else if (data.type === 'r2' && data.data) {
        listObj.temperature = data.data.temperature ? data.data.temperature : null;
        listObj.priceDisplay = priceDisplay(data.data.price, data.data.cy);
        // if (data.data.priceFurniture) {
        //     listObj.furniturePriceDisplay = priceDisplay(data.data.priceFurniture, data.data.cy);
        // }
        listObj.note = data.data.note ? data.data.note : null;
    }
    //SIGN MANDATE
    else if ((data.type === 'signMandate') && data.data) {
        listObj.temperature = data.data.temperature ? data.data.temperature : null;
        listObj.priceDisplay = priceDisplay(data.data.price, data.data.cy);
        listObj.priceNegDisplay = priceDisplay(data.data.priceNeg, data.data.cy);
        // if (data.data.priceFurniture) {
        //     listObj.furniturePriceDisplay = priceDisplay(data.data.priceFurniture, data.data.cy);
        // }
        listObj.exclusive = !!data.data.exclusive;
        listObj.duration = data.data.duration ? data.data.duration : null;
        listObj.dateAvailable = data.data.dateAvailable ? data.data.dateAvailable : null;
        listObj.incl = data.data.incl ? data.data.incl : null;
        listObj.note = data.data.note ? data.data.note : null;

    }
    // VISIT REPORT
    else if ((data.type === 'visitReport') && data.data) {
        listObj.com = !!data.data.com;
        listObj.medium = data.data.medium ? data.data.medium : null;
        listObj.send = !!data.data.send;
        listObj.sent = !!data.data.sent;
        listObj.note = data.data.note ? data.data.note : null;

    }
    // NEW PRICE
    else if (data.type === 'price' && data.data) {
        listObj.price = priceDisplay(data.data.price, data.data.cy);
        listObj.priceNeg = data.data.priceNeg ? priceDisplay(data.data.priceNeg, data.data.cy) : null;
        listObj.note = data.data.note ? data.data.note : null;
    }
    // PAYMENT
    else if (data.type === 'payment' && data.data) {
        listObj.amount = priceDisplay(data.data.amount, data.data.cy);
        listObj.ref = data.data.ref ? data.data.ref : null;
        listObj.paymentType = data.data.paymentType ? data.data.paymentType : null;
        listObj.paymentTypeText = data.data.paymentType ? preSettings.getParamName('paymentTypes', data.data.paymentType) : null;
    }
    // ADVERTISE
    else if (data.type === 'advertise' && data.data) {
        listObj.ad = !!data.data.ad;
        listObj.priceVisible = !!data.data.priceVisible;
    }
    // NEW MANDATE
    else if (data.type === 'newMandate' && data.data) {
        listObj.origin = data.data.origin ? data.data.origin : null;
        listObj.originName = _getMandateOrigin(data.data.origin);
        listObj.referral = data.data.referral ? data.data.referral : null;
        listObj.note = data.data.msg ? data.data.msg : null;
    }
    // HANDOVER
    else if ((data.type === 'handover' || data.type === 'temperature') && data.data) {
        listObj.from = data.data.from ? data.data.from : null;
        listObj.to = data.data.to ? data.data.to : null;
    }
    // FAILED
    else if (data.type === 'failed' && data.data) {
        listObj.failedReason = data.data.failedReason ? data.data.failedReason : null;
        listObj.note = data.data.note ? data.data.note : null;
    }

    // MANAGE SYNAPS
    if (data.synaps) {
        if (data.type === 'visit') {
            if (data.synaps[data.type]) {
                for (const matchingKey in data.synaps[data.type]) {
                    listObj.matchings.push(matchingKey);
                }
                delete listObj.mandate;
            }
        }
        if (data.type === 'offer' || data.type === 'signAgree' || data.type === 'signDeed' || data.type === 'signLease' || data.type === 'visitReport') {
            if (data.synaps[data.type]) {
                for (const matchingKey in data.synaps[data.type]) {
                    listObj.matching = matchingKey;
                }
            }
            if (data.data) {
                listObj.priceDisplay = priceDisplay(data.data.price, data.data.cy);
            }
        }
        // For all actions
        // console.log("TESSSSSSSSSSSSSSSSSSSSSSSSSSSSST 1 " + JSON.stringify(data.synaps));
        if (data.synaps.projectsActions) {
            // console.log("TESSSSSSSSSSSSSSSSSSSSSSSSSSSSST 2 ");
            for (const projectKey in data.synaps.projectsActions) {
                const project = data.synaps.projectsActions[projectKey];

                listObj.project.$key = projectKey;
                // console.log("TESSSSSSSSSSSSSSSSSSSSSSSSSSSSST 2");
                _getProjectData(listObj, project);
            }
        }
        if (data.synaps.mandatesActions) {
            for (const mandateKey in data.synaps.mandatesActions) {
                if (data.type === 'newRequest'
                    || data.type === 'offer'
                    || data.type === 'signLease'
                    || data.type === 'signAgree'
                    || data.type === 'signDeed'
                    || data.type === 'signMandate') {
                    const mandate = data.synaps.mandatesActions[mandateKey];
                    listObj.mandate.$key = mandateKey;
                    _getMandateData(listObj, mandate);
                    listObj.budget = priceDisplay(mandate.price, mandate.cy);
                } else {
                    listObj.mandate.$key = mandateKey;
                }
            }
        }
        if (data.synaps.residencesActions) {
            for (const residenceKey in data.synaps.residencesActions) {
                // const residence = data.synaps.residencesActions[residenceKey];
                listObj.residence.$key = residenceKey;
                // console.log("TESSSSSSSSSSSSSSSSSSSSSSSSSSSSST 2");
                // _getResidenceData(listObj, residence);
            }
        }
        // Manage Display Names on Table

        const actionTypes: any = preSettings.getParamValues('actionTypes');
        if (listObj.type && actionTypes[listObj.type] && actionTypes[listObj.type].info && actionTypes[listObj.type].info.fullname) {


            let select: string | null = null;
            if (actionTypes[listObj.type].info.fullname === 'select') {
                if (listObj.project && listObj.project.$key) {
                    select = 'projects';
                } else if (listObj.mandate && listObj.mandate.$key) {
                    select = 'mandates';
                }
            } else {
                select = actionTypes[listObj.type].info.fullname;
            }
            if (select) {
                if (select === 'projects') {
                    if (listObj.project && listObj.project.contactsProjects) {
                        listObj.fullnames = listObj.project.contactsProjects;
                    }
                }
                else if (select === 'mandates') {
                    if (listObj.mandate && listObj.mandate.owners) {
                        listObj.fullnames = listObj.mandate.owners;
                    }
                }
            }
        }

    }

    return listObj;
}

function _actionIconColor(data: any): string {
    if (data.type === 'newRequest' || data.type === 'visit') {
        return 'hot';
    }
    if (data.type === 'discovery') {
        return 'warm';
    }
    if (data.type === 'takeNews') {
        return 'cold';
    }
    return 'active';
}

function _getProjectOrigin(origin: string): string {
    const values = preSettings.getParamValues("projectOrigins");
    if (values[origin] && values[origin].name)
        return '' + values[origin].name;
    return 'Unknown';
}

function _getMandateOrigin(origin: string): string {
    const values = preSettings.getParamValues("mandateOrigins");
    if (values[origin] && values[origin].name)
        return '' + values[origin].name;
    return 'Unknown';
}

export function matchings(data: any) {

    const listObj: any = {
        $key: data.$key ? data.$key : null,
        created: data.created ? data.created : null,
        active: true,
        new: !!data.new,
        match: !!data.match,
        // com: !!data.com,
        visits: [],
        offer: false,
        signAgree: false,
        signDeed: false,
        signLease: false,
        // report: !!data.report,
        rate: data.rate ? data.rate : null,
        pos: _ObjectToArrayOrder(data.pos),
        neg: _ObjectToArrayOrder(data.neg),
        // note: data.note ? data.note : null,

        icon: data.new ? GLOBAL.ICONS.NEW : GLOBAL.ICONS.MATCHING,
        iconColor: 'cold',
        realtor: null,
        agency: null,
        score: 0,

        mandate: { owners: [] },
        project: {},
        // synaps: data.synaps ? data.synaps : {},
        // alias: alias,
    }

    if (data.synaps) {
        // MANDATE
        if (data.synaps.mandateMatchings) {
            for (const mandateKey in data.synaps.mandateMatchings) {
                const mandate = data.synaps.mandateMatchings[mandateKey];
                listObj.mandate.$key = mandateKey;
                _getMandateData(listObj, mandate);
                listObj.mandate.webURLs = getWebURLs(mandate);
            }
        }

        // PROJECT
        if (data.synaps.projectMatchings) {
            for (const projectKey in data.synaps.projectMatchings) {
                const project = data.synaps.projectMatchings[projectKey];
                listObj.project.$key = projectKey;
                _getProjectData(listObj, project);
                listObj.realtor = listObj.project.realtor ? listObj.project.realtor : null;
                listObj.agency = listObj.project.agency ? listObj.project.agency : null;
            }
        }

        // calcul score
        if (listObj.project.temperature && listObj.mandate.temperature) {
            listObj.score = _score(listObj.project.temperature) * _score(listObj.mandate.temperature);
            listObj.iconColor = matchingTemperature(listObj.score);
        }

        // VISITS
        if (data.synaps.visit) {
            for (const actionKey in data.synaps.visit) {
                listObj.visits.push(actionKey);
            }
        }
        // OFFER
        if (data.synaps.offer) {
            for (const actionKey in data.synaps.offer) {
                if (data.synaps.offer[actionKey]) {
                    listObj.offer = true;
                }
            }
        }
        // SIGN PURCHASE AGREEMEENT
        if (data.synaps.signAgree) {
            for (const actionKey in data.synaps.signAgree) {
                if (data.synaps.signAgree[actionKey]) {
                    listObj.signAgree = true;
                }
            }
        }
        // SIGN DEED
        if (data.synaps.signDeed) {
            for (const actionKey in data.synaps.signDeed) {
                if (data.synaps.signDeed[actionKey]) {
                    listObj.signDeed = true;
                }
            }
        }
        // SIGN LEASE
        if (data.synaps.signLease) {
            for (const actionKey in data.synaps.signLease) {
                if (data.synaps.signLease[actionKey]) {
                    listObj.signLease = true;
                }
            }
        }
        listObj.active = _matchingActive(listObj);
    }

    // console.log(JSON.stringify(listObj));

    return listObj;
}

export function matchingTemperature(score: number): string {
    if (score) {
        if (score < 4) {
            return 'cold';
        } else if (score < 9) {
            return 'warm';
        } else {
            return 'hot';
        }
    }
    return 'cold';
}

function _matchingActive(listObj: any): boolean {
    return (listObj.match || listObj.new) && listObj.mandate?.status === 'active' && listObj.project?.status === 'active';
}

function _score(temperature: string): number {
    if (temperature === 'hot')
        return 3;
    if (temperature === 'warm')
        return 2;
    if (temperature === 'cold')
        return 1;
    return 0;
}

function _ObjectToArrayOrder(obj: { [key: string]: { txt: string, order: number } }): string[] {
    const array: string[] = [];

    if (obj) {
        const arrayTemp: { txt: string, order: number }[] = [];

        for (const key in obj) {
            arrayTemp.push(obj[key]);
        }
        for (const v of arrayTemp.sort((a: any, b: any) => { return a.order - b.order })) {
            if (v.txt) {
                array.push(v.txt);
            }
        }

    }
    return array;
}


function _getProjectData(listObj: any, project: any) {
    listObj.project.ref = project.ref ? project.ref : '????';
    listObj.project.dateNeed = project.dateNeed ? project.dateNeed : null;
    listObj.project.minBedrooms = project.minBedrooms ? project.minBedrooms : null;
    listObj.project.budget = budgetDisplay(project);
    listObj.project.realtor = project.realtor ? project.realtor : null;
    listObj.project.agency = project.agency ? project.agency : null;
    listObj.project.status = project.status ? project.status : null;
    listObj.project.temperature = project.temperature ? project.temperature : null;
    listObj.project.type = project.type ? project.type : null;
    listObj.project.icon = preSettings.getParamIcon('projectStatus', project.status);
    listObj.project.iconColor = project.status === 'active' ? listObj.project.temperature : listObj.project.status;
    listObj.project.unitTypeIcons = getUnitTypesIcons(project.unitTypes);
    listObj.project.contactsProjects = [];

    if (project.synaps && project.synaps.contactsProjects) {
        for (const contactKey in project.synaps.contactsProjects) {
            if (project.synaps.contactsProjects[contactKey].fullname) {
                listObj.project.contactsProjects.push(project.synaps.contactsProjects[contactKey].fullname);
            }
        }
    }
}

function _getMandateData(listObj: any, mandate: any) {
    listObj.mandate.ref = mandate.ref ? mandate.ref : '????';
    listObj.mandate.dateAvailable = mandate.dateAvailable ? mandate.dateAvailable : null;
    // listObj.mandate.price = (mandate.priceNegCies && mandate.priceNegCies.mur) ? mandate.priceNegCies.mur : (mandate.priceCies ? mandate.priceCies.mur : null);
    listObj.mandate.priceDisplay = priceDisplay(mandate.price, mandate.cy);
    listObj.mandate.priceNegDisplay = priceDisplay(mandate.priceNeg, mandate.cy);
    listObj.mandate.realtor = mandate.realtor ? mandate.realtor : null;
    listObj.mandate.agency = mandate.agency ? mandate.agency : null;
    listObj.mandate.status = mandate.status ? mandate.status : null;
    listObj.mandate.temperature = mandate.temperature ? mandate.temperature : null;
    listObj.mandate.type = mandate.type ? mandate.type : null;
    listObj.mandate.icon = preSettings.getParamIcon('mandateStatus', mandate.status)
    listObj.mandate.iconColor = mandate.status === 'active' ? listObj.mandate.temperature : listObj.mandate.status;
    listObj.mandate.owners = [];

    if (mandate.synaps && mandate.synaps.unitMandates) {
        for (const unitKey in mandate.synaps.unitMandates) {
            const unit = mandate.synaps.unitMandates[unitKey];
            getUnitData(listObj.mandate, unit);
        }
    }
}


function getUnitData(listObj: any, unit: any) {
    listObj.unitType = unit.type ? unit.type : null;
    listObj.unitTypeIcon = unit.type ? (icons[unit.type] ? icons[unit.type] : null) : null;
    listObj.condition = unit.condition ? unit.condition : null;
    listObj.vefa = !!unit.vefa;
    listObj.pm = !!unit.pm;
    listObj.bedrooms = unit.bedrooms ? unit.bedrooms : null;
    listObj.bathrooms = unit.bathrooms ? unit.bathrooms : null;
    listObj.city = unit.address && unit.address.eCityName ? unit.address.eCityName : null;
    // listObj.unitNb = unit.address && unit.address.unitNb ? unit.address.unitNb : null;
    listObj.residence = null;
    listObj.gps = unit.address && unit.address.gps ? unit.address.gps : null;
    listObj.photo = unit.photo ? unit.photo : null;

    // Surface
    if (unit.type === 'land') {
        listObj.surface = unit.landSurface ? unit.landSurface : null;
    } else {
        listObj.surface = unit.builtSurface ? unit.builtSurface : (unit.livingSurface ? unit.livingSurface : null);
    }

    // RESIDENCE
    if (unit.synaps && unit.synaps.residenceUnits) {
        for (const residenceKey in unit.synaps.residenceUnits) {
            if (unit.synaps.residenceUnits[residenceKey].name) {
                listObj.residence = unit.synaps.residenceUnits[residenceKey].name;
                if (unit.address && unit.address.unitNb) {
                    listObj.residence += ' - ' + unit.address.unitNb;
                }
            }
            if (unit.vefa) {
                if (unit.synaps.residenceUnits[residenceKey] && unit.synaps.residenceUnits[residenceKey].synaps && unit.synaps.residenceUnits[residenceKey].synaps.promoter) {
                    for (const companyKey in unit.synaps.residenceUnits[residenceKey].synaps.promoter) {
                        if (unit.synaps.residenceUnits[residenceKey].synaps.promoter[companyKey] && unit.synaps.residenceUnits[residenceKey].synaps.promoter[companyKey].fullname) {
                            listObj.owners.push(unit.synaps.residenceUnits[residenceKey].synaps.promoter[companyKey].fullname);
                        }
                    }
                }
            }
        }
    }

    // OWNERS
    if (!unit.vefa && unit.synaps && unit.synaps.owners) {
        for (const contactKey in unit.synaps.owners) {
            if (unit.synaps.owners[contactKey].fullname) {
                // console.log(listObj.owners);
                listObj.owners.push(unit.synaps.owners[contactKey].fullname);
            }
        }
    }
}




//****************  Return Mandate optimise for matching filters  ************/
export function mandatesMatching(mandate: any): any {

    const listObj: any = {
        $key: mandate.$key ? mandate.$key : null,
        // alias: alias ? alias : {},
        dateAvailable: _checkUndefined(mandate.dateAvailable),
        priceCies: (mandate.priceNeg && mandate.priceNegCies) ? mandate.priceNegCies : mandate.priceCies ? mandate.priceCies : null,
        status: _checkUndefined(mandate.status),
        type: _checkUndefined(mandate.type),
        temperature: _checkUndefined(mandate.temperature),
        ref: _checkUndefined(mandate.ref),
        realtor: _checkUndefined(mandate.realtor),
        agency: _checkUndefined(mandate.agency),
        exclusive: _checkUndefined(mandate.exclusive),
        price: _checkUndefined(mandate.price),
        priceNeg: _checkUndefined(mandate.priceNeg),
        cy: _checkUndefined(mandate.cy),
        synaps: {
            unitMandates: mandate.synaps.unitMandates,
        }
    }

    for (const unitKey in mandate.synaps.unitMandates) {
        const unit = mandate.synaps.unitMandates[unitKey];

        const residence = mergeResidenceUnitSynap(unit);

        if (residence) {
            listObj.residence = true;
            listObj.scheme = residence.scheme ? residence.scheme : null;
            listObj.morc = !!residence.morc;
            listObj.totalUnits = residence.totalUnits ? residence.totalUnits : null;
        } else {
            listObj.residence = false;
        }

        listObj.unitType = unit.type ? unit.type : null;
        listObj.landType = unit.landType ? unit.landType : null;
        listObj.leasehold = unit.leasehold ? unit.leasehold : null;

        listObj.penthouse = !!unit.penthouse;
        listObj.garden = !!unit.garden;
        listObj.condition = unit.condition ? unit.condition : null;
        listObj.style = unit.style ? unit.style : null;
        listObj.landSurface = (unit.landSurface && unit.landUnit) ? unit.landSurface : null;
        listObj.landUnit = unit.landUnit ? unit.landUnit : null;
        listObj.landSurfaceM2 = land_m2(listObj.landSurface, listObj.landUnit);

        listObj.bedrooms = unit.bedrooms ? unit.bedrooms : null;
        listObj.bathrooms = unit.bathrooms ? unit.bathrooms : null;
        listObj.city = unit.address && unit.address.eCity ? unit.address.eCity : null;
        listObj.gps = unit.address && unit.address.gps ? unit.address.gps : null;
        listObj.sectors = unit.address && unit.address.sectors ? unit.address.sectors : [];

        listObj.includeAC = !!unit.includeAC;
        listObj.floor = unit.floor ? unit.floor : null;
        listObj.topFloor = unit.topFloor ? Number(unit.topFloor.substring(1)) : null;
        listObj.storeys = unit.storeys ? unit.storeys : null;
        listObj.kitchenType = unit.kitchenType ? unit.kitchenType : null;

        if (unit.params) {
            listObj.commonPool = !!unit.params.commonPool;
            listObj.privatePool = !!unit.params.privatePool;
            listObj.office = !!unit.params.office;
        }

        if (unit.equipment) {
            listObj.furnished = !!unit.equipment.furnished;
            listObj.semifurnished = !!unit.equipment.semifurnished;
            listObj.unfurnished = !!unit.equipment.unfurnished;
            listObj.petAllowed = !!unit.equipment.petAllowed;
            listObj.generator = !!unit.equipment.generator;
        }

        if (unit.appliances) {
            listObj.opticalFiber = !!unit.appliances.opticalFiber;
            listObj.wifi = !!unit.appliances.wifi;
        }

        if (unit.security) {
            listObj.alarm = !!unit.security.alarm;
            listObj.securityGuard = !!(unit.security.securityGuard || unit.security.watchman);
        }

        if (unit.view) {
            listObj.seaView = !!unit.view.seaView;
        }

        if (unit.area) {
            listObj.beachfront = !!unit.area.beachfront;
            listObj.seaAccess = !!unit.area.seaAccess;
            listObj.nearSchool = !!unit.area.nearSchool;
        }

    }

    return listObj;
}




function _checkUndefined(value: any): any {
    return value ? value : null;
}