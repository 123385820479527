import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MainsComponent } from 'src/common/pages/mains/mains.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { BlogService } from 'src/services/blog/blog.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent extends MainsComponent {

  constructor(
    public setting: SettingService,
    public db: BlogService,
    public select: SelectService,
    protected router: Router,
    protected fb: FormBuilder,
    public backURL: BackurlService,
  ) {
    super(setting, db, select, router, fb, backURL);
    
    this.sortLocalForm = this.fb.group({
      localSearch: [''],
    });
  }

  addItem() {
    this.backURL.addURL(`/marketing/blogs`);
    this.router.navigate([`/marketing/blogs/0`]);
  }

  goItem(item: any) {
    if (!this.select.active) {
      this.backURL.addURL(`/marketing/blogs`);
      this.router.navigate([`/marketing/blogs/${item.$key}`]);
    }
  }
}