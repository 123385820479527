import { Component, ChangeDetectorRef, Inject } from '@angular/core';
import { MainComponent } from '../../common/pages/main/main.component';
import { Contact } from '../../services/contact/contact.model';
import { Unit } from '../../services/unit/unit.model';
import { SettingService } from '../../common/services/setting/setting.service';
import { ContactService } from '../../services/contact/contact.service';
import { SelectService } from '../../common/services/select/select.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionService } from 'src/services/action/action.service';
import { Action } from 'src/services/action/action.model';
import { ProjectService } from 'src/services/project/project.service';
import { Project } from 'src/services/project/project.model';
import { LogsService } from 'src/common/services/logs/logs.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends MainComponent {

  public item: Contact;

  // To Use in Units Table
  public unitModel = new Unit();

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ContactService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    public dialog: MatDialog,
    private _log: LogsService,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Contact((this.select.active && this.select.prefil) ? this.select.prefil : null, setting);
  }

  protected _itemOnInit(): void {
    if (!this.isRole('admin')) {
      this._log.view('contacts', this.item.values.$key);
    }
  }

  addProject() {
    const data = { ok: false, item: this.item };
    const dialogRef = this.dialog.open(DialogAddProjectComponent, {
      width: '400px',
      disableClose: true,
      data: data
    });

  }
}



@Component({
  selector: 'app-dialog-add-project',
  templateUrl: './add-project.component.html',
})
export class DialogAddProjectComponent extends MainComponent {

  public actionItem = new Action(null, this.setting);
  private _realtor = '';

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ProjectService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialogRef: MatDialogRef<any>,
    public backURL: BackurlService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Project(null, setting);
    this.actionService = new ActionService(this.db.auth, this.db.afs, this.setting);

    // Set Default Agent & Agency...
    this.item.values.realtor = this.db.auth.uid;
    this._realtor = this.db.auth.uid;
    this.item.values.agency = this.db.auth.profile.values.agency ? this.db.auth.profile.values.agency : this.db.auth.profile.values.agencies ? this.db.auth.profile.values.agencies[0] : null;
    this.setting.setDynAgencies(this.item.values.realtor);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    if (this.data.item.values.$key) {
      if (!this.item.values.realtor) {
        this.item.values.realtor = this.db.auth.uid;
      }
      if (!this.item.values.agency) {
        this.item.values.agency = this.setting.getDefaultAgency(this.item.values.realtor);
      }
      // Check if realtor doing the new project
      let realtor = true;
      if (this.item.values.realtor !== this.db.auth.uid) {
        realtor = false;
      }

      if (realtor) {
        this.item.values.status = "discovery";
      } else {
        this.item.values.status = "new";
      }

      this.item.values.synaps = {
        contactsProjects: {
          [this.data.item.values.$key]: this.data.item.alias
        }
      }
      this.db.saveNewItem(this.item).then((newKey) => {
        this._createNewProjectAction(
          'newRequest',
          {
            projectType: this.item.values.type,
            origin: this.item.values.origin,
            originMedium: this.item.values.originMedium,
            msg: this.item.values.originNote,
            referral: this.item.values.referral,
          },
          realtor ? true: false);
        if (realtor) {
          this._createNewProjectAction('accept', null, true);
          this._createNewProjectAction('discovery');
        }
        this.backURL.addURL(`/contacts/${this.data.item.values.$key}`);
        this.router.navigate([`/projects/${newKey}`]);
        this.dialogRef.close();
      });
    } else {
      this.dialogRef.close();
    }
  }

  public touched() {
    // Check if realtor has changed, if Yes reset select agency...
    if (this._realtor !== this.item.values.realtor) {
      this.setting.setDynAgencies(this.item.values.realtor);
      this.setting.show = false;
      this.item.values.agency = null;
      this._realtor = this.item.values.realtor;
    }
  }

  private _createNewProjectAction(type: string, data: any = {}, done: boolean = false) {
    this.actionItem.resetItem();
    Object.assign(this.actionItem.values, {
      type: type,
      date: new Date(),
      data: data,
      done: done,
      doneDate: done ? new Date() : null,
      realtor: this.item.values.realtor,
      agency: this.item.values.agency,
      synaps:
        { projectsActions: { [this.item.values.$key]: this.db.createAlias(this.item) } },
    });

    this.actionService.saveNewItem(this.actionItem);
  }

}