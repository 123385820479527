import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { StatService } from 'src/services/stat/stat.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  public GLOBAL = new GlobalConstants();

  public statReports: any = {};

  public sortLocalForm: FormGroup = this.fb.group({
    agency: [null],
    realtor: [null],
    year: [null],
    month: [null],
  });

  public sortOptionsForm: FormGroup = this.fb.group({
    statReport: ['leads'],
    type: [null],
  });


  public customColors: any[] = [
    { name: 'Cold', value: '#B1E9E1' }, // Red
    { name: 'Warm', value: '#FF8C00' }, // Green
    { name: 'Hot', value: '#FF4500' }, // Blue
    // Add more mappings as needed
  ];

  // public months = [{ name: 'January', value: '01' },
  // { name: 'February', value: '02' },
  // { name: 'March', value: '03' },
  // { name: 'April', value: '04' },
  // { name: 'May', value: '05' },
  // { name: 'June', value: '06' },
  // { name: 'July', value: '07' },
  // { name: 'August', value: '08' },
  // { name: 'September', value: '09' },
  // { name: 'October', value: '10' },
  // { name: 'January', value: '11' },
  // { name: 'January', value: '12' },

  // public tableCols = [];
  public series: string | null = null;


  // view: any[] = [700, 400];

  // options
  // gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';


  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Year';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Leads';
  animations: boolean = true;




  // colorScheme = {
  //   domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  // };

  // single = [
  //   {
  //     "name": "Germany",
  //     "value": 8940000
  //   },
  //   {
  //     "name": "USA",
  //     "value": 5000000
  //   },
  //   {
  //     "name": "France",
  //     "value": 7200000
  //   },
  //   {
  //     "name": "UK",
  //     "value": 6200000
  //   }
  // ];

  constructor(
    public statService: StatService,
    public setting: SettingService,
    public route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    protected fb: FormBuilder,) {
  }

  ngOnInit(): void {


    // this.statService.initStatCols();
    // this._initTableCols('leads');
    this.statService.initList(this.sortOptionsForm.value.statReport, this.sortLocalForm.value.agency, this.sortLocalForm.value.realtor, parseInt(this.sortLocalForm.value.year), parseInt(this.sortLocalForm.value.month), this.sortOptionsForm.value.type);

    this.sortLocalForm.valueChanges.pipe(debounceTime(500)).subscribe(filters => {
      setTimeout(() => {
        this.series = null;
        this.statService.initList(this.sortOptionsForm.value.statReport, this.sortLocalForm.value.agency, this.sortLocalForm.value.realtor, parseInt(this.sortLocalForm.value.year), parseInt(this.sortLocalForm.value.month), this.sortOptionsForm.value.type);
      }, 10);
    });

    this.sortOptionsForm.valueChanges.pipe(debounceTime(500)).subscribe(filters => {
      setTimeout(() => {
        this.series = null;
        this.statService.refreshChartOptions(this.sortOptionsForm.value.statReport, this.sortOptionsForm.value.type, parseInt(this.sortLocalForm.value.year), parseInt(this.sortLocalForm.value.month));
      }, 10);
    });
  }

  // private _initTableCols(statGroup: string) {
  //   this.tableCols = ['date'];
  //   if (this.statService.statCols[statGroup]) {
  //     for (const field of this.statService.statCols[statGroup]) {
  //       this.tableCols.push(field.id);
  //     }
  //   }
  // }

  goBack() {
    this.router.navigate([this.backURL.back()]);
  }

  backToList() {
    this.series = null;
  }

  onSelectBar(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    this.series = data.series;
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}


