import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  private _itemsSubcription: any;
  public logs$: Observable<any[]> = null
  logs: any[] = [];
  public loaded = false;

  constructor(
    protected afs: AngularFirestore,
    protected auth: AuthService,
  ) { }

  init(module: string, $key: string) {


    this.logs$ = this._colWithIds(`/logs`, (ref) => this._queryFn(ref, module, $key));

    this.loaded = false;

    if (this._itemsSubcription) {
      this._itemsSubcription.unsubscribe();
    }

    this._itemsSubcription = this.logs$.subscribe(items => {
      this.logs = items;
      this.loaded = true;
    });
  }

  view(module: string, $key: string) {
    firebase.firestore().doc(`logs/${firebase.firestore().collection('/logs').doc().id}`).set(
      {
        created: firebase.firestore.FieldValue.serverTimestamp(),
        item: {
          key: $key,
          module: module,
        },
        uid: this.auth.uid,
        type: 'view',
      });
      
  }

  protected _queryFn(ref, module: string, $key: string): any {

    let query = ref.where('item.module', '==', module);
    query = query.where('item.key', '==', $key);

    query = query.orderBy('created', 'desc');

    return query;
  }

  protected _colWithIds(path, queryFn?): Observable<any[]> {
    return this.afs.collection(path, queryFn).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            return a.payload.doc.data();
            // return new this.itemClass(a.payload.doc.data());
          });
        })
      );
  }
}
