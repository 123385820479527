import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { addZero } from 'functions/src/shared/tools';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingService } from 'src/common/services/setting/setting.service';

@Injectable({
  providedIn: 'root'
})
export class StatService {

  private _itemsSubcription: any;
  public statList$: Observable<any[]> = null
  public statList: any[] = [];
  public statChart: any[] = [];
  public statChartSeries: { [seriesName: string]: any } = {};
  public loaded = false;

  // public statCols = {
  //   marketing: []
  // }


  statConfs = {
    actions: {
      name: 'Actions Report',
      mandateType: false,
      fields: 'actions',
      legend: this.setting.getParamsObj('actionTypes'),
    },
    leads: {
      name: 'Leads Report',
      mandateType: true,
      fields: 'newRequest',
      legend: this.setting.getParamsObj('projectOrigins'),
    },
    projects: {
      name: 'Stock Projects',
      mandateType: true,
      fields: 'projects',
      legend: this.setting.getParamsObj('temperatures'),
    },
    mandates: {
      name: 'Stock Mandates',
      mandateType: true,
      fields: 'mandates',
      legend: this.setting.getParamsObj('temperatures'),
    },
  }

  constructor(
    protected afs: AngularFirestore,
    protected setting: SettingService,
  ) {
  }

  initList(statReport: string, agency: string, realtor: string, year: number, month: number, mandateType: string) {

    // this.stats$ = this._colWithIds(`/stats`, (ref) => this._queryFn(ref, module, $key));

    let dbpath = `/stats/${realtor ? 'realtors' : (agency ? 'agencies' : 'all')}/${(year && month) ? 'days' : (year ? 'months' : 'years')}/`;

    this.statList$ = this._colWithIds(dbpath, (ref) => this._queryFn(ref, year, month, agency, realtor));

    this.loaded = false;

    if (this._itemsSubcription) {
      this._itemsSubcription.unsubscribe();
    }

    this._itemsSubcription = this.statList$.subscribe(items => {
      this.statList = items;
      this.statChart = this._toChart(statReport, mandateType, year, month);
      this.loaded = true;
    });
  }

  private _queryFn(ref, year: number, month: number, agency: string, realtor: string): any {

    let query = ref;

    if (year) {
      query = query.where('year', '==', year);
      if (month) {
        query = query.where('month', '==', month);
      }
    }
    if (realtor) {
      query = query.where('realtor', '==', realtor);
    } else if (agency) {
      query = query.where('agency', '==', agency);
    }

    query = query.orderBy('date', 'asc');

    return query;
  }

  public refreshChartOptions(statReport: string, mandateType: string, year: number, month: number) {
    this.statChart = this._toChart(statReport, mandateType, year, month);
  }

  private _toChart(statReport: string, mandateType: string, year: number, month: number): any[] {
    const tmp: any[] = [];
    this.statChartSeries = {};

    const statConf = this.statConfs[statReport];

    const daysInMonth = new Date(year, month, 0).getDate();
    const dateMap = new Map<string, any>();

    for (const item of this.statList) {
      if (item.date?.seconds) {
        const jsDate = item.date.toDate();
        const dateKey = '' + (month ? addZero(jsDate.getDate()) : (year ? addZero(jsDate.getMonth() + 1) : item.year));
        const obj = {
          name: dateKey,
          series: []
        };

        for (const field in statConf.legend) {
          if (field !== 'all') {
            obj.series.push({
              name: statConf.legend[field].name,
              value: mandateType ? ((item[statConf.fields] && item[statConf.fields][mandateType] && item[statConf.fields][mandateType][field]) ? item[statConf.fields][mandateType][field] : 0) : ((item[statConf.fields] && item[statConf.fields][field]) ? item[statConf.fields][field] : 0),
            });
          }
        }

        this.statChartSeries[dateKey] = obj.series;
        dateMap.set(dateKey, obj);
      }
    }
    if (year && month) {
      for (let day = 1; day <= daysInMonth; day++) {
        const dateKey = addZero(day);
        if (!dateMap.has(dateKey)) {
          const obj = {
            name: dateKey,
            series: []
          };

          for (const field in statConf.legend) {
            if (field !== 'all') {
              obj.series.push({
                name: statConf.legend[field].name,
                value: 0,
              });
            }
          }

          this.statChartSeries[dateKey] = obj.series;
          tmp.push(obj);
        } else {
          tmp.push(dateMap.get(dateKey));
        }
      }
    } else if (year) {
      for (let month = 1; month <= 12; month++) {
        const dateKey = addZero(month);
        if (!dateMap.has(dateKey)) {
          const obj = {
            name: dateKey,
            series: []
          };

          for (const field in statConf.legend) {
            if (field !== 'all') {
              obj.series.push({
                name: statConf.legend[field].name,
                value: 0,
              });
            }
          }

          this.statChartSeries[dateKey] = obj.series;
          tmp.push(obj);
        } else {
          tmp.push(dateMap.get(dateKey));
        }
      }
    }


    return tmp;
  }


  // initStatCols() {
  //   this.statCols.marketing = this.setting.getParamsSelect('projectOrigins');
  // }


  protected _colWithIds(path, queryFn?): Observable<any[]> {
    return this.afs.collection(path, queryFn).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            return a.payload.doc.data();
            // return new this.itemClass(a.payload.doc.data());
          });
        })
      );
  }
}
